import React from "react";

function MenuIcon(props) {
  let defaultProps = {fill: "#000000", width: 24, height: 24};
  props = Object.assign(defaultProps, props);
  return (
    <svg fill={props.fill} width={props.width} height={props.height} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
    </svg>
  );
}

export default React.memo(MenuIcon);
