import React from "react";

function CloseIcon(props) {
  let defaultProps = {fill: "#000000", width: 24, height: 24};
  props = Object.assign(defaultProps, props);
  return (
    <svg fill={props.fill} width={props.width} height={props.height} viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
    </svg>
  );
}

export default React.memo(CloseIcon);
