import React from "react";
import HubspotForm from "react-hubspot-form";
import {graphql, useStaticQuery} from "gatsby";
import {isEmpty} from "lodash";
import Img from "components/image";
import Link from "components/link";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS} from "constants/index";
import partnersMaps from "images/partners-maps.svg";

const HUBSPOT_FORM_ID = "d5e309a0-a247-4a4f-990c-c03bdfdcd6b6";

function ContactPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  let [hubspotFormReady, setHubspotFormReady] = React.useState(false);
  let [hubspotFormSubmitted, setHubspotFormSubmitted] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("message", handleHubspotFormSubmit);
    return () => {
      window.removeEventListener("message", handleHubspotFormSubmit);
    };
  }, []);

  function handleHubspotFormReady(event) {
    setHubspotFormReady(true);
  }

  function handleHubspotFormSubmit(event) {
    if(event.data.type === "hsFormCallback") {
      if (event.data.eventName === "onFormSubmit") {
        setHubspotFormSubmitted(true);
      }
    }
  }

  function renderFormSection() {
    let hint, placeholder;
    let privacyLink = <a href="/legal/privacy"
      className="underline text-orange"
      target="_blank" rel="noopener noreferrer">
      Privacy Notice
      </a>;
    if (hubspotFormSubmitted === true) {
      placeholder = <div className="h-4 w-full"/>;
    }
    if (hubspotFormReady === true && hubspotFormSubmitted === false) {
      hint = <p className="text-black text-sm my-4">
        By clicking “Send”, you acknowledge that you have read, and consent to
        collection, use and disclosure of your personal data for the purposes
        set out in our {privacyLink}.
      </p>;
    }
    return <section id="form" className="relative">
      <div className="container mx-auto px-8 py-4 z-10 relative max-w-4xl bg-white"
        style={{minHeight: "200px"}}>
        {placeholder}
        <HubspotForm portalId={process.env.GATSBY_HUBSPOT_ID} formId={HUBSPOT_FORM_ID}
          onReady={handleHubspotFormReady}
          onSubmit={handleHubspotFormSubmit}/>
        {hint}
      </div>
    </section>;
  }

  function renderPhoneNumbers() {
    if (isEmpty(pageContent.phoneNumbers)) return null;
    return pageContent.phoneNumbers.map((item) => {
      return <div key={item.number} className="flex">
        <div className="font-bold w-10 mb-1">{item.label}</div>
        <a href={`tel:${item.number}`}>{item.number}</a>
      </div>;
    });
  }

  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <section className="relative min-h-screen flex items-center">
      <div className="container mx-auto px-4 sm:px-8 z-10 relative">
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
          {pageContent.headerText}
        </h3>
        <p className="mt-4 text-center max-w-4xl mx-auto">
          {pageContent.subHeaderText}
        </p>
      </div>
      <Img {...IMAGE_BACKGROUND_PROPS}
        fluid={data.backgroundImage.childImageSharp.fluid} alt="Background"/>
    </section>
    {renderFormSection()}
    <section className="relative min-h-screen flex items-center">
      <div className="container mx-auto px-4 sm:px-8 z-10 relative">
        <h3 className="font-bold text-4xl lg:text-6xl leading-tight whitespace-pre-wrap">
          Tigers Around<br/>
          The World
        </h3>
        <div>
          <Link to="/about/" className="btn btn-orange mt-6 inline-block">
            About Us
          </Link>
        </div>
        <div className="mt-20">
          {renderPhoneNumbers()}
        </div>
      </div>
      <img src={partnersMaps} alt="Background"
        style={{position: "absolute", right: "0", width: "1000px", maxWidth: "100%", zIndex: 1, pointerEvents: "none"}}/>
    </section>
  </DefaultLayout>;
}

const QUERY = graphql`
  {
    pageContent: file(relativePath: {eq: "pages/contact.json"}) {
      childPagesJson {
        title
        description
        headerText
        subHeaderText
        phoneNumbers {
          label
          number
        }
      }
    }
    backgroundImage: file(relativePath: {eq: "background-contact.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: JPG) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default React.memo(ContactPage);
