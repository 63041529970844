import React from "react";
import {Helmet as Head} from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";
import {isEmpty} from "lodash";
import Header from "./header";
import Footer from "./footer";
import "styles/global.css";
import "styles/fonts.css";

function DefaultLayout(props) {
  let data = useStaticQuery(QUERY);
  let {siteMetadata} = data.site;
  let title = props.title || siteMetadata.title;
  let description = props.description || siteMetadata.description;
  let pageUrl = siteMetadata.siteUrl + props.location.pathname + props.location.hash;

  React.useEffect(() => {
    if ("ontouchstart" in window) {
      document.body.classList.add("touch");
    } else {
      document.body.classList.add("no-touch");
    }
  }, []);

  function getOgImageUrl() {
    let {metaImage} = props;
    if (isEmpty(metaImage)) metaImage = "/og-image.png";
    if (/^[a-z][a-z\d+.-]*:/.test(metaImage)) {
      /** Path is absolute */
      return metaImage;
    } else {
      /** Prepend siteUrl for relative path */
      return siteMetadata.siteUrl + metaImage;
    }
  }

  return <React.Fragment>
    <Head htmlAttributes={{lang: "en", class: "default-layout"}}>
      <title>{title}</title>
      <script src="https://unpkg.com/react-collapse/build/react-collapse.min.js"></script>
      <meta name="description" content={description}/>
      <link rel="apple-touch-icon" href="/icon.png"/>
      <link rel="icon" type="image/x-icon" href="/favicon.ico"/>
      <link rel="icon" type="image/png" sizes="192x192"  href="/icon.png"/>
      <meta property="og:url" content={pageUrl}/>
      <meta property="og:title" content={title}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={getOgImageUrl()}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image" content={getOgImageUrl()}/>
    </Head>
    {props.nav && <Header url={props.location.pathname} fill={props.headerFill}/>}
    {props.children}
    <Footer/>
  </React.Fragment>;
}

const QUERY = graphql`
  {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
  }
`;

DefaultLayout.defaultProps = {
  nav: true
}

export default React.memo(DefaultLayout);
