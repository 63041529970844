import React from "react";
import Link from "components/link";
import footerLogo from "images/footer-logo.svg";

function Footer() {
  return <footer className="w-full">
    <div className="container mx-auto px-4 sm:px-8 flex flex-col-reverse sm:flex-row items-start">
      <img className="align-top mt-6 sm:mt-0 pointer-events-none"
        src={footerLogo} width={60} alt="Horangi"/>
      <div className="flex-1"/>
      <div className="flex flex-wrap sm:flex-0 mt-4 sm:mt-0 w-full sm:w-auto">
        <div className="mr-8 sm:mr-16">
          <h4 className="text-sm uppercase font-bold mb-4">Offerings</h4>
          <Link to="/products/" className="text-sm block mb-2">Products</Link>
          <Link to="/solutions/" className="text-sm block mb-2">Solutions</Link>
          <Link to="/products/trainer" className="text-sm block mb-2">Trainer</Link>
        </div>
        <div className="mr-8 sm:mr-16">
          <h4 className="text-sm uppercase font-bold mb-4">Resources</h4>
          <Link to="/blog/" className="text-sm block mb-2">Blog</Link>
          {/* <Link to="/" className="text-sm block mb-2">Case Studies</Link> */}
        </div>
        <div>
          <h4 className="text-sm uppercase font-bold mb-4">About</h4>
          {/* <Link to="/events/" className="text-sm block mb-2">Events</Link> */}
          {/* <Link to="/" className="text-sm block mb-2">Latest News</Link> */}
          <Link to="/careers/" className="text-sm block mb-2">Careers</Link>
          {/* <Link to="/" className="text-sm block mb-2">Bug Bounty</Link> */}
        </div>
      </div>
    </div>
    <div className="banner bg-gray-100 py-4 mt-8">
      <div className="container mx-auto px-4 sm:px-8">
        <Link to="/legal/" className="text-sm text-gray-600 font-medium mr-4">Legal</Link>
        <Link to={process.env.GATSBY_SUPPORT_URL} className="text-sm text-gray-600 font-medium mr-4">Horangi Academy</Link>
        <Link to="/contact/" className="text-sm text-gray-600 font-medium mr-4">Contact Us</Link>
      </div>
    </div>
  </footer>;
}

export default React.memo(Footer);
