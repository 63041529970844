export const IMAGE_BACKGROUND_PROPS = {
  style: {
    position: "absolute",
    top: 0, left: 0,
    width: "100%", height: "100%",
    zIndex: 1,
    pointerEvents: "none"
  }
};

export const EXTERNAL_LINK_PROPS = {
  target: "_blank",
  rel: "noopener noreferrer"
};

export const YOUTUBE_CONFIG = {
  playerVars: {autoplay: 1, fs: 0}
};
