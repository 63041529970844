import React from "react";
import { FaChevronUp } from "react-icons/fa";
import AnimateHeight from "react-animate-height";
import { Link } from "gatsby";
import styles from "./styles.module.css";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

const NAV_LINK_DEFAULT_PROPS = {
  activeClassName: "active",
  partiallyActive: true
};

const MobileNavigation = ({ isOpen }) => {
  let [solutionsHeight, setSolutionsHeight] = React.useState(0);
  let [cloudHeight, setCloudHeight] = React.useState(0);
  let [complianceHeight, setComplianceHeight] = React.useState(0);
  let [servicdeHeight, setServiceHeight] = React.useState(0);

  if (!isOpen) {
    return null;
  }

  function handleClickSolutions() {
    // setIsSolutionsOpen(!isSolutionsOpen);
    setSolutionsHeight(solutionsHeight === 0 ? "auto" : 0);
  }
  function handleClickCloud() {
    setCloudHeight(cloudHeight === 0 ? "auto" : 0);
  }

  function handleClickCompliance() {
    setComplianceHeight(complianceHeight === 0 ? "auto" : 0);
  }

  function handleClickServices() {
    setServiceHeight(servicdeHeight === 0 ? "auto" : 0);
  }

  function renderServicesLinks() {
    const Links = () => (
      <div className="flex flex-col">
        <Link
          className={styles.mobile_nested_link}
          {...NAV_LINK_DEFAULT_PROPS}
          to="/solutions/penetration-testing"
        >
          Penetration Testing
        </Link>
        <Link
          className={styles.mobile_nested_link}
          {...NAV_LINK_DEFAULT_PROPS}
          to="/solutions/vciso"
        >
          vCISO
        </Link>
      </div>
    );
    return (
      <AnimateHeight duration={500} height={servicdeHeight}>
        <Links />
      </AnimateHeight>
    );
  }

  function renderComplianceLins() {
    const Links = () => (
      <div className="flex flex-col">
                <Link
          className={styles.mobile_nested_link}
          {...NAV_LINK_DEFAULT_PROPS}
          to="/solutions/cyber-hygiene"
        >
          Cyber Hygiene
        </Link>
        <Link
          className={styles.mobile_nested_link}
          {...NAV_LINK_DEFAULT_PROPS}
          to="/solutions/compliance"
        >
          Compliance
        </Link>
        <Link
          className={styles.mobile_nested_link}
          {...NAV_LINK_DEFAULT_PROPS}
          to="/solutions/data-privacy"
        >
          Data Privacy
        </Link>
      </div>
    );
    return (
      <AnimateHeight duration={500} height={complianceHeight}>
        <Links />
      </AnimateHeight>
    );
  }

  function renderCloudLinks() {
    const Links = () => (
      <div className="flex flex-col">
        <Link
          className={styles.mobile_nested_link}
          {...NAV_LINK_DEFAULT_PROPS}
          to="/products/warden"
        >
          Warden
        </Link>
        <Link
          className={styles.mobile_nested_link}
          {...NAV_LINK_DEFAULT_PROPS}
          to="/solutions/security-aws"
        >
          Security on AWS
        </Link>
      </div>
    );
    return (
      <AnimateHeight duration={500} height={cloudHeight}>
        <Links />
      </AnimateHeight>
    );
  }

  return (
    <SlideDown className={styles.mobile_nav_wrapper}>
      {isOpen ? (
        <div>
          <Link
            className={styles.mobile_nav_link}
            {...NAV_LINK_DEFAULT_PROPS}
            to="/products/warden"
          >
            Warden
          </Link>
          <div
            onClick={handleClickSolutions}
            className={`${styles.mobile_nav_link} ${styles.mobile_nav_chevron_wrapper}`}
          >
            Solutions
            <FaChevronUp
              color={solutionsHeight === "auto" ? "#707070" : "#CFCFCF"}
              className={`${styles.fa_arrow_down} ${
                solutionsHeight === "auto" ? styles.open : ""
              }`}
            />
          </div>
          <AnimateHeight duration={500} height={solutionsHeight}>
            <div style={{ backgroundColor: "#2B2B2B" }}>
              <div
                onClick={handleClickCompliance}
                className={`${styles.mobile_dropdown_link} ${styles.mobile_nav_chevron_wrapper}`}
              >
                COMPLIANCE
                <FaChevronUp
                  color={complianceHeight === "auto" ? "#707070" : "#CFCFCF"}
                  className={`${styles.fa_arrow_down} ${
                    complianceHeight === "auto" ? styles.open : ""
                  }`}
                />
              </div>
              {renderComplianceLins()}
              <div
                onClick={handleClickServices}
                className={`${styles.mobile_dropdown_link} ${styles.mobile_nav_chevron_wrapper}`}
              >
                SERVICES
                <FaChevronUp
                  color={servicdeHeight === "auto" ? "#707070" : "#CFCFCF"}
                  className={`${styles.fa_arrow_down} ${
                    servicdeHeight === "auto" ? styles.open : ""
                  }`}
                />
              </div>
              {renderServicesLinks()}
              <div
                onClick={handleClickCloud}
                className={`${styles.mobile_dropdown_link} ${styles.mobile_nav_chevron_wrapper}`}
              >
                CLOUD SECURITY
                <FaChevronUp
                  color={cloudHeight === "auto" ? "#707070" : "#CFCFCF"}
                  className={`${styles.fa_arrow_down} ${
                    cloudHeight === "auto" ? styles.open : ""
                  }`}
                />
              </div>
              {renderCloudLinks()}
            </div>
          </AnimateHeight>
          <Link
            className={styles.mobile_nav_link}
            {...NAV_LINK_DEFAULT_PROPS}
            to="/customers"
          >
            Customers
          </Link>
          <Link
            className={styles.mobile_nav_link}
            {...NAV_LINK_DEFAULT_PROPS}
            to="/partners"
          >
            Partners
          </Link>
          <Link
            className={styles.mobile_nav_link}
            {...NAV_LINK_DEFAULT_PROPS}
            to="/about"
          >
            About
          </Link>
          <Link
            className={styles.mobile_nav_link}
            {...NAV_LINK_DEFAULT_PROPS}
            to="/blog"
          >
            Blog
          </Link>
          <a
            href="https://app.horangi.com/login"
            className={styles.mobile_sign_in_link}
          >
            Sign In
          </a>
        </div>
      ) : null}
    </SlideDown>
  );
};

export default MobileNavigation;
