import React from "react";
import { Link as SmoothScroll } from "react-scroll";
import Tippy from "@tippy.js/react";
import { filterLinks } from "utils/navigation-utils";
import { Link, useStaticQuery, graphql, navigate } from "gatsby";
import { invoke, kebabCase } from "lodash";
import MenuIcon from "components/icons/menu";
import CloseIcon from "components/icons/close";
import style from "styles/layout.module.css";
import logo from "images/main-logo.svg";

import MobileNav from "./mobile-navigation";

function renderLinks(links) {
  function handleClick() {
    global.document.body.click();
  }
  return links.map(item => {
    return (
      <Link
        key={kebabCase(item.headerText)}
        to={item.ctaUrl}
        onClick={handleClick}
      >
        {item.headerText}
      </Link>
    );
  });
}

function SolutionsTooltip(props) {
  let data = useStaticQuery(QUERY);
  let { solutions } = data.solutions.childPagesJson;
  const complianceLinks = filterLinks(solutions, [
    "Cyber Hygiene",
    "Compliance",
    "Data Privacy"
  ]);
  const serviceLinks = filterLinks(solutions, ["Penetration Testing", "vCISO"]);
  const cloudLinks = [
    { headerText: "Warden", ctaUrl: "/products/warden" },
    { headerText: "Security on AWS", ctaUrl: "/solutions/security-aws" }
  ];
  return (
    <div className="flex w-full pr-3">
      <div className="w-auto p-5">
        <h5 className="font-bold text-center">COMPLIANCE</h5>
        <div className="mt-2">{renderLinks(complianceLinks)}</div>
      </div>
      <div className="w-auto p-5">
        <h5 className="font-bold">SERVICES</h5>
        <div className="-ml-6 mt-2">{renderLinks(serviceLinks)}</div>
      </div>
      <div className="w-auto p-5 -ml-3">
        <h5 className="font-bold">CLOUD SECURITY</h5>
        <div className="-ml-6 mt-2">{renderLinks(cloudLinks)}</div>
      </div>
    </div>
  );
}

function AboutTooltip(props) {
  return (
    <div>
      {/* <Link to="/events">Events</Link> */}
      {/* <Link to="/about/latest-news/">Latest News</Link> */}
      <Link to="/careers/">Careers</Link>
    </div>
  );
}

function Header(props) {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);
  let [tooltipDisabled, setTooltipDisabled] = React.useState(true);
  let [navigationStyle, setNavigationStyle] = React.useState();
  // let [menuStyle, setMenuStyle] = React.useState({ height: 0 });
  let rootRef = React.useRef();
  let rootHeight = React.useRef(0);
  const [height, setHeight] = React.useState(0);
  React.useEffect(() => {
    setHeight(height === 0 ? "auto" : 0);
  }, [isMobileNavOpen]);
  // let menuRef = React.useRef();

  const NAV_LINK_DEFAULT_PROPS = {
    className: style.navLink,
    activeClassName: "active",
    partiallyActive: true
  };

  const TIPPY_DEFAULT_PROPS = {
    theme: "horangi",
    arrow: false,
    placement: "bottom-start",
    interactive: true,
    distance: 12,
    animation: "fade",
    animateFill: false,
    inertia: false,
    offset: -24,
    onShow: handleTippyShow,
    maxWidth: 600
  };

  React.useLayoutEffect(() => {
    updateRootHeight();
    setTimeout(updateRootHeight, 200);
    window.addEventListener("resize", updateRootHeight);
    return () => document.removeEventListener("resize", updateRootHeight);
  }, []);

  React.useLayoutEffect(() => {
    updateNavigationStyle({ transition: "none" });
    window.addEventListener("scroll", updateNavigationStyle);
    return () => document.removeEventListener("scroll", updateNavigationStyle);
  }, []);

  React.useEffect(() => {
    document.addEventListener("mousemove", enableTooltip);
    return () => document.removeEventListener("mousemove", enableTooltip);
  }, []);

  function updateRootHeight() {
    if (rootRef.current) {
      rootHeight.current = rootRef.current.offsetHeight;
    }
  }

  function updateNavigationStyle(options = {}) {
    let background = options.background || "rgba(0, 0, 0, 0)";
    let transition = options.transition || "background 240ms";
    if (props.fill === "always" || global.scrollY > rootHeight.current) {
      background = "rgba(0, 0, 0, 1)";
    }
    setNavigationStyle({ background, transition });
  }

  function enableTooltip() {
    setTooltipDisabled(false);
    document.removeEventListener("mousemove", enableTooltip);
  }

  function handleTippyShow() {
    if (tooltipDisabled === true) return false;
  }

  function handleMenuClick(event) {
    event.preventDefault();
    setIsMobileNavOpen(!isMobileNavOpen);
    // setMenuStyle(prevStyle => {
    //   let newHeight;
    //   if (prevStyle.height === 0) {
    //     newHeight = getNodeDimensions(menuRef.current).height;
    //   } else {
    //     newHeight = 0;
    //   }
    //   return { ...prevStyle, height: newHeight };
    // });
  }

  function handleContactButtonClick(event) {
    invoke(event, "preventDefault");
    let href = event.target.getAttribute("href");
    navigate(href);
    if (window && window.ga) {
      window.ga("send", "event", "Link", "click", "Contact Us");
    }
  }

  function renderMenuButton() {
    let icon;
    let iconProps = { fill: "#FFFFFF", width: 32, height: 32 };
    if (!isMobileNavOpen) {
      icon = <MenuIcon {...iconProps} />;
    } else {
      icon = <CloseIcon {...iconProps} />;
    }
    return (
      <a
        href="/"
        className="block ml-4 lg:hidden"
        aria-label="Menu"
        onClick={handleMenuClick}
      >
        {icon}
      </a>
    );
  }

  function handleContactUs() {
    let contactUsBtn;
    const url = props.url;
    // Work on a better solution
    if(url.includes("/solutions/cyber-hygiene")){
      contactUsBtn = (
        <SmoothScroll
          activeClass="active"
          to="hubspot-form"
          spy={true}
          smooth={true}
          offset={-200}
          duration={800}
        >
          <div className={`${style.actionButton} btn btn-orange cursor-pointer`}>
            Contact Us
          </div>
        </SmoothScroll>
      );
    } else {
      contactUsBtn = (
        <a
          href="/contact/"
          onClick={handleContactButtonClick}
          className={`${style.actionButton} btn btn-orange`}
        >
          Contact Us
        </a>
      );
    }
    // let contactUsBtn;
    // switch (props.url) {
    //   case "/solutions/cyber-hygiene":
    //     contactUsBtn = (
    //       <SmoothScroll
    //         activeClass="active"
    //         to="hubspot-form"
    //         spy={true}
    //         smooth={true}
    //         offset={-200}
    //         duration={800}
    //       >
    //         <div className={`${style.actionButton} btn btn-orange cursor-pointer`}>
    //           Contact Us
    //         </div>
    //       </SmoothScroll>
    //     );
    //     break;
    //   default:
    //     contactUsBtn = (
    //       <a
    //         href="/contact/"
    //         onClick={handleContactButtonClick}
    //         className={`${style.actionButton} btn btn-orange`}
    //       >
    //         Contact Us
    //       </a>
    //     );
    // }
    return contactUsBtn;
  }

  return (
    <header ref={rootRef} className="w-full fixed top-0 left-0 z-30">
      <div
        className={`navigation relative border-box flex items-center bg-black z-30`}
        style={navigationStyle}
      >
        <div className="container mx-auto px-2 flex items-center h-12 md:h-16 my-4">
          <Link to="/" className="logo h-full flex-none">
            <img className="align-top max-h-full" src={logo} alt="Horangi" />
          </Link>
          <nav className="ml-16 font-sans text-sm hidden lg:block">
            <Link {...NAV_LINK_DEFAULT_PROPS} to="/products/warden">
              Warden
            </Link>
            <Tippy {...TIPPY_DEFAULT_PROPS} content={<SolutionsTooltip />}>
              <Link {...NAV_LINK_DEFAULT_PROPS} to="/solutions/">
                Solutions
              </Link>
            </Tippy>
            <Link {...NAV_LINK_DEFAULT_PROPS} to="/customers">
              Customers
            </Link>
            <Link {...NAV_LINK_DEFAULT_PROPS} to="/partners/">
              Partners
            </Link>
            <Tippy {...TIPPY_DEFAULT_PROPS} content={<AboutTooltip />}>
              <Link {...NAV_LINK_DEFAULT_PROPS} to="/about/">
                About
              </Link>
            </Tippy>
            <Link {...NAV_LINK_DEFAULT_PROPS} to="/blog/">
              Blog
            </Link>
            <a
              className={style.navLink}
              rel="nofollow"
              data-outline
              href={process.env.GATSBY_APP_URL}
            >
              Sign In
            </a>
          </nav>
          <div className="flex-1" />
          {handleContactUs()}
          {renderMenuButton()}
        </div>
      </div>
      <MobileNav isOpen={isMobileNavOpen} />
    </header>
  );
}

const QUERY = graphql`
  {
    solutions: file(relativePath: { eq: "pages/solutions.json" }) {
      childPagesJson {
        solutions {
          headerText
          ctaUrl
        }
      }
    }
  }
`;

export default React.memo(Header);
